//====================================================
//  Function: Select
//====================================================
function componentSelect() {
  if ($('.js-select').length) {
    $('.js-select')
      .select2({
        minimumResultsForSearch: Infinity,
        placeholder: function () {
          $(this).data('placeholder')
        },
      })
      .focus(function () {
        $(this).select2('open')
      })

    selectEvents($('.js-select'))
  }

  if ($('.js-select-object').length) {
    $('.js-select-object')
      .select2({
        minimumResultsForSearch: Infinity,
        templateResult: typeOfState,
        placeholder: function () {
          $(this).data('placeholder')
        },
      })
      .focus(function () {
        $(this).select2('open')
      })

    selectEvents($('.js-select-object'))
  }
}

function typeOfState(state) {
  if (!state.id) {
    return state.text
  }
  var optionState = state.element.dataset.state
  var template = `
		<span class="select-option">
			<span class="select-option-state">
			<img src="images/flags/${optionState}.svg" loading="lazy">
			</span>
			<span class="select-option-text"> ${state.text} </span>
		</span>
	`
  var $state = $(template)
  return $state
}

function selectEvents(element) {
  element.on('select2:open', function (e) {
    $(this).closest('.js-placeholder-block').addClass(classIsActive)
  })

  element.on('select2:select', function (e) {
    $(this).closest('.js-placeholder-block').addClass(classIsActive)
    $(this).trigger('input')
  })

  element.on('select2:close', function (e) {
    if (!$(this).val().length) {
      $(this).closest('.js-placeholder-block').removeClass(classIsActive)
    }
  })
}
