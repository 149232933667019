/* ==========================================================================
 *  Function: Dropdown
 * ======================================================================= */
function partDropdown() {
  if ($('.js-dropdown-trigger').length) {
    $('.js-dropdown-trigger').on('click', function () {
      if ($(window).width() < bpMedium) {
        var inst = $(this)
        var thisDropdownItem = inst.closest('.js-dropdown-item')
        var dropdownBlock = inst.closest('.js-dropdown-block')

        if (thisDropdownItem.hasClass(classIsActive)) {
          thisDropdownItem.toggleClass(classIsActive)
        } else {
          dropdownBlock
            .find('.js-dropdown-item.' + classIsActive + '')
            .removeClass(classIsActive)
          inst.closest('.js-dropdown-item').addClass(classIsActive)
        }
        return false
      }
    })
  }
}
